<template>
  <div>
    <b-card title="Basic Information">

      <!-- media -->
      <!--    <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  :src="userData.avatar"
                  height="80"
                />
              </b-link>
              &lt;!&ndash;/ avatar &ndash;&gt;
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              &lt;!&ndash; upload button &ndash;&gt;
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputEl.$el.click()"
              >
                Upload
              </b-button>
              <b-form-file
                ref="refInputEl"
                v-model="profileFile"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
                @input="inputImageRenderer"
              />
              &lt;!&ndash;/ upload button &ndash;&gt;

              &lt;!&ndash; reset &ndash;&gt;
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                size="sm"
                class="mb-75 mr-75"
              >
                Reset
              </b-button>
              &lt;!&ndash;/ reset &ndash;&gt;
              <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
            </b-media-body>
          </b-media>-->
      <!--/ media -->

      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form class="mt-2">
          <b-row>
            <b-col sm="6">
              <b-form-group
                label="Name"
                label-for="account-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    v-model="userData.name"
                    name="name"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Contact Name"
                label-for="contact_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="contact_name"
                  rules="required"
                >
                  <b-form-input
                    v-model="userData.contact_name"
                    name="contact_name"
                    placeholder="Contact Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Phone"
                label-for="phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <b-form-input
                    v-model="userData.phone"
                    name="phone"
                    placeholder="Phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Mobile"
                label-for="mobile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="mobile"
                  rules="required"
                >
                  <b-form-input
                    v-model="userData.mobile"
                    name="mobile"
                    placeholder="Mobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="E-mail"
                label-for="account-e-mail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required"
                >
                  <b-form-input
                    v-model="userData.email"
                    name="email"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group
                label="country"
                label-for="country"
              >
                <validation-provider
                  #default="{ errors }"
                  name="country"
                  rules="required"
                >
                  <v-select
                    id="v-country"
                    v-model="userData.country_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countries"
                    :reduce="country => country.id"
                    label="title"
                    placeholder="Country"
                    @option:selected="countrySelected"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="City"
                label-for="v-city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                >
                  <v-select
                    id="v-city"
                    v-model="userData.city_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="cities"
                    :disabled="!userData.country_id"
                    label="title"
                    placeholder="City"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- alert -->
            <b-col
              class="mt-75"
              cols="12"
            >
              <!--          <b-alert
                          show
                          variant="warning"
                          class="mb-50"
                        >
                          <h4 class="alert-heading">
                            Your email is not confirmed. Please check your inbox.
                          </h4>
                          <div class="alert-body">
                            <b-link class="alert-link">
                              Resend confirmation
                            </b-link>
                          </div>
                        </b-alert>-->
            </b-col>
            <!--/ alert -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card title="Client Address">
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <b-row
          v-for="(item, index) in userData.addresses"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <!-- Item Name -->
          <b-col md="4">
            <b-form-group
              label="Name"
              label-for="item-name"
            >
              <validation-provider
                #default="{ errors }"
                name="address name"
                rules="required"
              >
                <b-form-input
                  id="item-name"
                  v-model="userData.addresses[index]['name']"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Add name for your address"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Address"
              label-for="item-address"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
                rules="required"
              >
                <b-form-input
                  id="item-address"
                  v-model="userData.addresses[index]['address']"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Add your address"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
            class="mb-50"
            lg="2"
            md="3"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              class="mt-0 mt-md-2"
              variant="outline-danger"
              @click="removeItem(index)"
            >
              <feather-icon
                class="mr-25"
                icon="XIcon"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
        </b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            class="mr-25"
            icon="PlusIcon"
          />
          <span>Add more addresses</span>
        </b-button>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col md="5" />
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-2 mr-1"
              type="submit"
              variant="primary"
              @click.prevent="validationForm"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="mt-2"
              type="button"
              variant="outline-secondary"
              @click.prevent="$router.push('/')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { required } from '@core/utils/validations/validations'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import axios from '@/libs/axios'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      userData: {
        name: '',
        contact_name: '',
        phone: '',
        mobile: '',
        city_id: '',
        email: '',
        customer_type_id: '',
        account_type_id: '',
        details: null,
        username: '',
        country_id: '',
        addresses: [{
          name: '',
          address: '',
        }],
      },
      countries: [],
      cities: [],
      customer_types: [],
      account_types: [],
      required,
    }
  },
  mounted() {
    this.getCountries()
    this.getAccountTypes()
    this.getCustomerTypes()
    this.getUserData()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    validationForm() {
      // eslint-disable-next-line no-underscore-dangle
      this.userData._method = 'PUT'
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          axios.post('/client', this.userData).then(resp => {
            if (resp) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Data is submitted successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              // this.$router.push('/user')
            }
          }).catch(() => {
            // console.log(err.response.data.errors)
            /* this.$toast({
              component: ToastificationContent,
              props: {
                title: 'some error occurred',
                icon: 'EditIcon',
                variant: 'danger',
              },
            }) */
          })
        }
      })
    },
    getUserData() {
      axios.get('/get_client').then(resp => {
        this.userData = resp.data
        this.userData.country_id = resp.data.city.country_id
        if (this.userData.country_id) {
          this.countrySelected(this.userData.country_id, false)
          this.userData.city_id = resp.data.city
        }
      })
    },
    getCountries() {
      axios.get('/countries/').then(resp => {
        this.countries = resp.data.data
      })
    },
    getCustomerTypes() {
      axios.get('/customerTypes/').then(resp => {
        this.customer_types = resp.data.data
      })
    },
    getAccountTypes() {
      axios.get('/accountTypes/').then(resp => {
        this.account_types = resp.data.data
      })
    },
    async countrySelected(value, clearCity = true) {
      let valueId = ''
      if (typeof value === 'object') {
        valueId = value.id
      } else {
        valueId = value
      }
      await axios.post('/cities/search', {
        filters: [{ field: 'country_id', operator: '=', value: valueId }],
      }).then(resp => {
        if (clearCity) {
          this.userData.city_id = null
        }
        this.cities = resp.data.data
      })
    },
    repeateAgain() {
      this.userData.addresses.push({
        name: '',
        address: '',
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.userData.addresses.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
  },
  initTrHeight() {
    this.trSetHeight(null)
    this.$nextTick(() => {
      this.trSetHeight(this.$refs.form.scrollHeight)
    })
  },
  setup() {
    /* const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    } */
  },
}
</script>
<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
