<template>
  <b-tabs
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    nav-class="nav-left"
    nav-wrapper-class="col-md-3 col-12"
    pills
    vertical
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          class="mr-50"
          icon="UserIcon"
          size="18"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          class="mr-50"
          icon="LockIcon"
          size="18"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

  </b-tabs>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingsComponents/AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingsComponents/AccountSettingPassword.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {

  },
}
</script>
