<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Old Password"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                name="old Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="form.passwordValueOld"
                    :type="passwordFieldTypeOld"
                    name="old-password"
                    placeholder="Old Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label="New Password"
              label-for="account-new-password"
            >
              <validation-provider
                #default="{ errors }"
                name="New Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="form.newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label="Retype New Password"
              label-for="account-retype-new-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Retype Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="form.newPasswordValue_confirmation"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-1 mr-1"
              type="submit"
              variant="primary"
              @click.prevent="validationForm"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="mt-1"
              type="button"
              variant="outline-secondary"
              @click.prevent="$router.push('/')"
            >
              Cancel
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import axios from '@/libs/axios'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        passwordValueOld: '',
        newPasswordValue: '',
        newPasswordValue_confirmation: '',
      },
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    validationForm() {
      // eslint-disable-next-line no-underscore-dangle
      this.form._method = 'PUT'
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          axios.post('/changePassword', this.form).then(resp => {
            if (resp) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Data is submitted successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              // this.$router.push('/user')
            }
          }).catch(err => {
            // eslint-disable-next-line no-restricted-syntax
            for (const [, value] of Object.entries(err.response.data.errors)) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: value[0],
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            }
          })
        }
      })
    },
  },
}
</script>
